import React from "react" // {Suspense}
// import { Link, graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
// import SpeedImage from "../components/speedImages"
import SEO from "../components/seo"

// infographic

import AllProfiles from '../components/utils/Profiles/Allprofiles'
import Infographic from '../components/utils/Infographic/Infographic'

// loading icon
// import Loading from '../components/icons/loading.js'

// lazy load iframe: https://medium.freecodecamp.org/how-to-use-react-lazy-and-suspense-for-components-lazy-loading-8d420ecac58
// const TPWidget = React.lazy(() => import("../components/utils/TPWidget"));
import TPContent from "../components/utils/TPWidget/TPContent";




const IndexPage = () => {

  

  return(
  <Layout>
    <SEO title="Simple and flexible flights around the world"  />
    
    {/* Trip Planner Widget and Content */}
    <TPContent />

    <Infographic /> 

    <AllProfiles title="Meet Our Expert Travel Planners" />


    {/*  
    <StaticQuery 
      query={data}
      render = { props => {
          const {edges} = props.allWordpressPage; 
          return(
          <>
          {edges.map(page => 
            <div key={page.node.id}>
              <Link to={page.node.slug}>
                <h1>{page.node.title}</h1>
                <div dangerouslySetInnerHTML={{__html: page.node.excerpt }}></div>
              </Link>
            </div>
          )}
          </>
          )
        }
      }
    />  
    */}
      {/* <SpeedImage src="gatsby-astronaut.png" /> */}
    
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
  )
}

export default IndexPage
// http://content.travelkitties.com/wp-json/wp/v2/pages

/*
const data = graphql`
  {
    allWordpressPage {
    totalCount
    edges {
      node {
        id
        modified(fromNow:true)
        wordpress_id
        slug
        status
        title
        excerpt
      }
    }
  }
}
`;
*/
