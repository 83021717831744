import React from 'react'
import {Icons} from '../../icons/icons'
import FlexBox from '../FlexBox'
import GuideRow from './GuideRow'


const Infographic = () => 
<>




<FlexBox 
  hasClass="infographic"
  
>
{/* flexDirection = "column" */}
<GuideRow 
    icon = {Icons.dream}
    title="1 | DREAM UP YOUR ROUND-THE-WORLD TRIP" 
    content="Enter your list of destinations using Trip Planner, our visual tool designed for international, multi-stop trips, and send us your idea."   
  />

  <GuideRow 
    icon = {Icons.comment}
    title="2 | OPTIMISE AND BOOK YOUR ROUTE" 
    content="Work directly with your personal travel planner, who has previously traveled the world, to refine and book your trip."   
  />

  <GuideRow 
    icon = {Icons.takeOff}
    title="3 | TRAVEL THE WORLD!" 
    content="Enjoy 24/7 support offered by our team of airfare support experts before and during your travels."   
  />
</FlexBox>
</>

export default Infographic
