import React from 'react'
import FlexItem from '../FlexItem'
import styled from 'styled-components'

const GuideRow = (props) => 

<FlexItem
  padding = '1rem'
  textAlign = 'center'
>
<div className="col">
  <Icon className="icon" dangerouslySetInnerHTML={{ '__html' :  props.icon }} />

  <div className="description">
    <Title>{props.title}</Title>
    <p>{props.content}</p>
  </div>
</div>
</FlexItem>


export default GuideRow

const Title = styled.h2`
  font-size:1.2rem;
  color: ${props => props.theme.color.red}
`

const Icon = styled.div`
  width:60px;
  height: 60px;
  display: flex;
  margin:auto;

  svg {
    width:100% !important;
    height: 100% !important;
    fill: #444;
  }
`