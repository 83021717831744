import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

const TPWidget = (props) => 
  <Map title="tpwidget"
    src={props.url} 
    width={props.hasWidth} 
    height={props.hasHeight} 
    frameBorder="0"> 
  </Map>

//props
TPWidget.defaultProps = {
  url: '#',
  hasWidth: '',
  hasHeight: '',
}

TPWidget.propTypes = {
  /** url as string and required. */
  url: PropTypes.string.isRequired,
  /** CTA Text */
  hasWidth: PropTypes.string,
  hasHeight: PropTypes.string,
}

export default TPWidget

const Map = styled.iframe`
  width:100% !important;
  max-width: ${props=> props.theme.wide.desktopPlus};
`


