import React from 'react'
import NetworkInformation from 'react-network-info';
import Holder from '../Holder'
import CTA from '../../utils/CTA'
import ImageBackground from '../ImageBackground'
import styled from 'styled-components'
import {screenSize} from '../../../themes/default'
import MediaQuery from 'react-responsive';

import  TPWidget from './TPWidget'




const TPContent = () => 
<NetworkInformation>
{props => 
  <Holder>
    <TPHolder>
      <Title>Take the trip of your life</Title>
      <Text>Our travel planners research thousands of flight combinations to create one-of-a-kind routes for your next round-the-world trip</Text>


      
        {/* <Suspense fallback={<div style={{ margin: `auto` }}><Loading /></div>}>
          <TPWidget 
            url="https://tripplanner.airtreks.com/widget" 
            hasWidth="900" 
            hasHeight="450"  
          />
        </Suspense> */}
        {props.effectiveType === '4g' ? (
            
          <MediaQuery minWidth={ screenSize.smallDesktop }>
          {(matches) => {
            if (matches) {
              return (
                <TPWidget 
                  url="https://tripplanner.airtreks.com/widget" 
                  hasWidth="900" 
                  hasHeight="450"  
                />
              );
            } else {
              return (
                <CTA 
                url="https://tripplanner.airtreks.com/" 
                text="Start your journey here" />
              );
            }
          }}
          
          </MediaQuery>

           ) : (
            <CTA 
              url="https://tripplanner.airtreks.com/" 
              text="Start your journey here" />
          )
        }

           
          
        { /*  style="max-width:900px; width:100%"  */ }
      
    </TPHolder>

    <ImageBackground src="fly3.jpg" darkerby="70" />
  </Holder>
}
</NetworkInformation>

export default TPContent

const TPHolder = styled.section`
  position: relative;
  z-index:10;
  max-width: ${props=> props.theme.wide.desktopPlus};
  width:100%;
  margin:0 auto;
  text-align: center;
  overflow: hidden
`

const Text = styled.p`
  max-width: ${props=> props.theme.wide.tabletHoriz};
  margin: 0 auto 1rem;
  font-size: 1.23rem;
`

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
`
